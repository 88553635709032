<template>
	<Modal v-model="showAdvice" width="1024"  @on-visible-change="onVisibleChange">
		<p slot="header">
			<span>房屋智能估价系统使用声明</span>
		</p>
		<div class="detail">
			<div class="title" style="margin: 20px 0;">
				尊敬的用户：
			</div>
			<div class="con">
				您好！感谢您使用由广东中思拓大数据研究院有限公司（简称中思拓研究院）开发的房屋智能估价系统（简称“中思拓房屋智能估价系统”或“本系统”、“系统”）进行房屋询价，中思拓房屋智能估价系统为您所查询的标的房屋提供当前市场价格参考。
			</div>
			<div class="con black">
				您在使用本系统询价前，须认真阅读并接受如下声明为前提：
			</div>
			<div class="con black">
				1.查询人和结果使用方须完全接受系统所设定的全部假设和限制条件为前提（特别需关注影响估价结论的因素及使用的限制要求）；
			</div>
			<div class="con black">
				2.系统根据您所提供的所需询价的标的物房屋的关键信息（座落、面积、层数等），参考当前市场价格参数，综合分析影响估价对象价值的主要因素，遵循独立、客观、公正、合法的原则，运用大数据估价方法，结合大数据模型算法和互联网大数据，在满足本次估价全部假设和限制条件下自动分析生成结果。
			</div>
			<div class="con black">
				3.本系统仅分析影响估价对象价值的主要因素，未考虑房屋室内因素（建设质量、装修等级、房屋维护、使用状况、房屋瑕疵等）、税费负担情况、权利限制情况（抵押担保、出租等）、和未知的不确定因素对分析结果的影响，并假定估价对象产权明晰，手续齐全、市场在询价期内保持稳定、可自由交易、查询人所提供的信息是准确的。

			</div>
			<div class="con black">
				4.我们并未对估价对象进行现场勘查，中思拓研究院不承担对估价对象的质量、现场情况、特殊情况及其他方面进行勘查、检测的责任。

			</div>
			<div class="con black">
				5.系统询价目的是根据大数据估算询价对象价值，了解、参考房屋的价值。未征得本系统所有者书面同意，使用者不得向其他单位和个人提供本系统的询价页面及询价结果的任何内容，禁止将询价页面及询价结果用于任何的商业用途。本系统的询价页面及询价结果不可被认为是对询价对象可实现价格的保证，不作为任何交易建议和交易估价的依据，中思拓研究院不承担相关当事人决策和交易的责任。

			</div>
			<div class="con">
				6.询价人应提供询价所需必要的房屋信息，并保证所提供信息的真实性、合法性、完整性，询价人及相关使用方应按本说明第5项限制的用途恰当使用本系统的询价页面及询价结果，并自行承担全部责任及风险；中思拓研究院不承担询价方和使用人使用本系统的询价页面及询价结果所产生的全部风险和责任。
			</div>
			<div class="con">
				7.估价时点应为查询当日，询价结果自出具之日起1个月内有效，在估价结果有效期内，估价对象状况或者住房市场发生明显变化的，应当进行相应调整。
			</div>
			<div class="con">
				8.未征得询价系统所有者书面同意，询价页面及询价结果全部或部分内容不得被摘抄、引用或披露于公开媒体，法律、政策规定以及相关当事方另有约定的除外。
			</div>
			<div class="con">
				9.系统所有者在法律许可范围内保留对分析数据和软件算法的最终解释权。
			</div>
			<div class="con black" style="text-align: right;">
				广东中思拓大数据研究院有限公司
			</div>
		</div>

		<div slot="footer"  style="text-align: center;">
			<Button type="primary" @click="ok">我已阅读</Button>
		</div>
	</Modal>
</template>

<script>
export default {
	props: {
		adviceBookModel: {
			type: Boolean,
			required: true,
		}
	},
	data() {
		return {

		}
	},
	computed: {
		showAdvice: {
			get() {
				return this.adviceBookModel
			},
			set(newVal) {
				this.$parent.showAdviceBookModel = newVal
			}

		}
	},
	methods: {
		ok() {
			this.showAdvice = false
			this.$emit('closeModel')
		},
		onVisibleChange(){
			this.$emit('closeModel')

		},
	}
}
</script>

<style scoped lang="scss">
.detail {
	height: 550px;
	overflow-y: scroll;
}

.title {
	font-size: 24px;
	font-weight: 500;
}

.con {
	text-indent: 2em;
	font-size: 16px;
}

.black{
	// color: #3D3D3D;
	color: black;
	font-weight: 500;
}
</style>
