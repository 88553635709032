<template>
  <div>
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :show-message="false" :label-width="80">
      <slot></slot>
      <FormItem label="面积" prop="buildingArea">
        <el-input v-track="{ event: 'click', route: $route, isButton: false, log: '面积' }"  v-model="formValidate.buildingArea" size="small" @blur="buildingAreaBlur"
          :disabled="faForm.buildingArea !== '' && noAllowedChange" @input="change" placeholder="请输入面积"
          style="max-width: 485px;">
          <div slot="suffix" style="line-height: 32px;">㎡</div>
        </el-input>
        <!-- <el-input v-model="formValidate.buildingArea" maxlength="15"
          onkeyup="value=value.replace(/^0+(\d)|[^\d]|/^(\d+.?)?\d{0,2}+/g,'')" style="width: 485px;"><span
            slot="append">万元</span></el-input> -->
      </FormItem>
      <FormItem label="" v-show="showMore && isShowALL">
        <div class="more" @click="showMore = false" v-track="{ event: 'click', route: $route, isButton: true, log: '更多' }">更多
          <Icon type="ios-arrow-forward" />
        </div>
      </FormItem>

      <div v-show="!showMore">
        <FormItem label="朝向">
          <el-select clearable v-model="formValidate.orientation" size="small"

            :disabled="faForm.orientation !== '' && noAllowedChange" placeholder="请选择" style="width: 485px;">
            <el-option v-for="item in TowardsList" :value="item.value" :key="item.value">{{ item.label }}</el-option>
          </el-select>
        </FormItem>
        <FormItem label="房屋户型">
          <Row :gutter="0">
            <Col span="6"><el-select clearable v-model="formValidate.roomCnt"   size="small"
              :disabled="faForm.roomCnt !== '' && noAllowedChange" placeholder="房" style="max-width: 106px;">
              <el-option v-for="item in 15" :value="item" :label="(item + '房')" :key="item - 1">{{ `${item}房` }}
              </el-option>
            </el-select></Col>
            <Col span="6"><el-select clearable v-model="formValidate.parlourCnt" size="small"
              :disabled="faForm.roomCnt !== '' && noAllowedChange" placeholder="厅" style="max-width: 106px;">
              <el-option v-for="item in 15" :value="item" :label="(item + '厅')" :key="item - 1">{{ `${item}厅` }}
              </el-option>
            </el-select></Col>
            <Col span="6"><el-select clearable v-model="formValidate.kitchenCnt" size="small"
              :disabled="faForm.roomCnt !== '' && noAllowedChange" placeholder="厨" style="max-width: 108px;">
              <el-option v-for="item in 15" :value="item " :label="(item + '厨')" :key="item - 1">{{ `${item}厨` }}
              </el-option>
            </el-select></Col>
            <Col span="6"><el-select clearable v-model="formValidate.toiletCnt" size="small"
              :disabled="faForm.roomCnt !== '' && noAllowedChange" placeholder="卫" style="max-width: 108px;">
              <el-option v-for="item in 15" :value="item " :label="(item + '卫')" :key="item - 1">{{ `${item}卫` }}
              </el-option>
            </el-select></Col>
          </Row>
        </FormItem>
        <FormItem label="楼层">
        <Col span="">
                  <el-select clearable v-model="formValidate.floors" size="small" placeholder="请选择"
            :disabled="faForm.floors !== '' && noAllowedChange" @change="changeFloors" style="width: 485px;">
            <el-option v-for="item in FlootAtList" :value="item.value" :key="item.value">{{ item.value }}</el-option>
          </el-select>
        </Col>

        </FormItem>

        <FormItem label="总楼层">
          <el-select clearable v-model="formValidate.totalFloors" size="small" placeholder="请选择"
            :disabled="faForm.totalFloors !== '' && noAllowedChange" @change="changeTotalFloors"
            style="width: 485px;">
            <el-option v-for=" item   in TotalFloorList" :value="item.value" :key="item.value">{{ item.value
            }}</el-option>
          </el-select>
        </FormItem>
        <FormItem label="建成年代">
          <el-date-picker clearable type="year" size="small" v-model="formValidate.completionYear"
            :disabled="faForm.completionYear !== '' && noAllowedChange" placeholder="选择年份"
            :pickerOptions="pickerOptions" style="width: 100%; max-width: 485px;"></el-date-picker>
        </FormItem>
        <FormItem label="有无电梯">
          <el-select clearable v-model="formValidate.isHaveLift" size="small" placeholder="请选择"
            :disabled="faForm.isHaveLift !== '' && noAllowedChange" style="width: 485px;">
            <el-option v-for="item in elevatorList" :value="item.value" :key="item.value">{{ item.label }}</el-option>
          </el-select>
        </FormItem>
        <FormItem label="" v-if="isShowALL">
          <div class="bottom-title">
            <!-- <div class="err-msg" v-if="isShowMoreAndNot" @click="errMsg" v-track="{ event: 'click', route: $route, isButton: true, log: '房屋信息有误或不全' }">房屋信息有误或不全，去反馈</div> -->
            <div class="more" v-show="!showMore" @click="showMore = true" style="">收起
            </div>
          </div>
        </FormItem>

        <!-- <Button type="primary" @click="handleSubmit('formValidate')">Submit</Button> -->
      </div>
    </Form>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'BaseForm',
  props: {
    faForm: {
      type: Object,
      default: () => ({
        buildingArea: '',//面积
        orientation: '',//朝向
        roomCnt: '',//房间数量
        parlourCnt: '',//客厅数量
        kitchenCnt: '',//厨房数量
        toiletCnt: '',//卫生间数量
        floors: '',//楼层
        totalFloors: '',//总楼层
        completionYear: '',//年代
        isHaveLift: '',//电梯
      })
    },
    noAllowedChange: {//是否可以被修改
      type: Boolean,
      default: false,
    },
    isShowMoreAndNot: {//是否显示信息有误
      type: Boolean,
      default: true,
    },
    isShowALL: {//是否显示更多,收起等字样
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formValidate: JSON.parse(JSON.stringify(this.faForm)),
      showMore: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      // buildingArea: '', //面积
      ruleValidate: {
        buildingArea: [
          { required: true, message: '请输入面积', trigger: 'blur' },
          //面积校验未添加，
          // { pattern:  /^\d{0,1}(\d{0,4})\.{0,1}(\d{1,2})?$/, message: '请输入正整数或小数，小数位数不超过2位', trigger: 'blur' }
        ],
        communityName: [
          { required: true, message: '请选择小区', trigger: 'blur' },

        ],
        street: [
          { required: true, message: '请选择楼栋', trigger: 'blur' },
        ],
        houseNumber: [
          { required: true, message: '请选择房号', trigger: 'blur' },
        ],
        city: [
          { required: true, message: '请选择城市', trigger: 'blur' },
        ],
        certAddress: [
          { required: true, message: '请输入地址', trigger: 'change' },
        ],

      },
      TowardsList: [{
        value: "东",
        label: "东",
      },
      {
        value: "南",
        label: "南",
      },
      {
        value: "西",
        label: "西",
      },
      {
        value: "北",
        label: "北",
      },
      {
        value: "东南",
        label: "东南",
      },
      {
        value: "东北",
        label: "东北",
      },
      {
        value: "西南",
        label: "西南",
      },
      {
        value: "西北",
        label: "西北",
      },
      {
        value: "东西",
        label: "东西",
      },
      {
        value: "南北",
        label: "南北",
      },
      ],
      FlootAtList: [],
      TotalFloorList: [],
      elevatorList: [{
        value: '有',
        label: "有",
      },
      {
        value: '无',
        label: "无",
      },
      ],

    };
  },
  //过滤器
  filters: {
    //过滤器
    filtersHoseStyle(val) {
      return val + '房'
    },
  },
  watch: {
    faForm: {
      deep: true, //深度监听设置为 true
      handler: function (newV, oldV) {
        this.formValidate = {}
        // this.formValidate =  JSON.parse(JSON.stringify( newV ))
        // this.formValidate =  newV

    this.initData()

        this.$nextTick(() => {
          this.formValidate = _.cloneDeep(newV)


        })
      }
    }
  },
  created() {

  },
  mounted() {
    if (!this.isShowALL) {
      this.showMore = false
    }
    this.initData()

  },
  methods: {
    buildingAreaBlur(val) {
      const reg = /^\d{0,1}(\d{0,4})\.{0,1}(\d{1,2})?$/ig;
      if ( this.formValidate.buildingArea === "") {
					return
				} else if (this.formValidate.buildingArea >= 5000 || this.formValidate.buildingArea <= 0) {
					// this.buildingAreaErrorMessage = "面积应大于0小于5000平方米"
					this.formValidate.buildingArea = ''
            this.$msg.error({text: "面积应大于0小于5000平方米"} );
				} else if (!reg.test(this.formValidate.buildingArea)) {
					// this.buildingAreaErrorMessage = "请输入正整数或小数，小数位数不超过2位"
					this.formValidate.buildingArea = ''
            this.$msg.error( {text: "请输入正整数或小数，小数位数不超过2位"} );
				} else {
					this.buildingAreaErrorMessage = ''
				}
    },
    errMsg() {
      this.$emit('errMsg',);

    },
    initData() {//初始化一些数据
    this.FlootAtList = []
    this.TotalFloorList = []
      for (let i = 1; i <= 99; i++) {
        let obj = {}
        obj.value = i
        obj.label = i
        this.FlootAtList.push(obj)
        this.TotalFloorList.push(obj)
      }
    },
    handleSubmit(name) {
      // this.$refs[name].validate((valid) => {
      //   if (valid) {
      //     this.$msg.success('Success!');
      //   } else {
      //   }
      // })
    },
    change(val) {
      // val = val.replace(/(^\s*)|(\s*$)/g, "")
      // if (!val) {
      //   this.pageNum = "";
      //   return
      // }
      // let reg = /^\d{0,1}(\d{0,4})\.{0,1}(\d{1,2})?$/g
      // // 只能是数字和小数点，不能是其他输入
      // val = val.replace(reg, "")
      // // 保证第一位只能是数字，不能是点
      // val = val.replace(/^\./g, "");
      // // 小数只能出现1位
      // val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      // // 小数点后面保留2位
      // // val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      // if (!reg.test(this.formValidate.buildingArea) ){
      //    this.formValidate.buildingArea = ''
      // }else{
      // this.formValidate.buildingArea = val;

      // }
    },

    changeFloors(val) {//楼层改变
      // this.TotalFloorList = val
      if (!val) return


      this.TotalFloorList = []

      for (let i = val; i <= 99; i++) {
        let obj = {}
        obj.value = i
        obj.label = i
        this.TotalFloorList.push(obj)
      }

    },
    changeTotalFloors(val) {//总楼层改变
      if (!val) return
      this.FlootAtList = []
      for (let i = 1; i <= val; i++) {
        let obj = {}
        obj.value = i
        obj.label = i
        this.FlootAtList.push(obj)
      }


      // this.FlootAtList = val
    },
  }
};
</script>

<style scoped lang="scss">
.bottom-title {

  width: 100%;
  // margin-left: 80px;

}

.err-msg {
  float: left;
  cursor: pointer;
  color: #0077FF;
}

.more {
  width: 50px;
  // background-color: red;
  cursor: pointer;
  float: right;
  color: #0077FF;
  margin-right: 12px;
  text-align: right;
}
</style>
