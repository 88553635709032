export default {
	cityList: [
	{
provinceCode:"44",
cityCode:"440100",
countyCode:"",
provinceShortName:"广东",
cityShortName:"广州",
countyShortName:"",
provinceFullName:"广东省",
cityFullName:"广州市",
countyFullName:""
},{
provinceCode:"440000000000",
cityCode:"440600",
countyCode:"",
provinceShortName:"广东",
cityShortName:"佛山",
countyShortName:"",
provinceFullName:"广东省",
cityFullName:"佛山市",
countyFullName:""
},{
provinceCode:"440000000000",
cityCode:"441900000000",
countyCode:"",
provinceShortName:"广东",
cityShortName:"东莞",
countyShortName:"",
provinceFullName:"广东省",
cityFullName:"东莞市",
countyFullName:""
}
	]

}


