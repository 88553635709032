<template>
  <Modal v-model="showInfo" :mask-closable="false" :footer-hide="true" width="700">
    <div slot="header">提交房屋信息</div>
    <Row :gutter="16" index="" style="width:604px;margin-left: 34px;">
      <Col span="24">
      <BaseForm :isShowMoreAndNot="false" :noAllowedChange="false" :faForm="form" :isShowALL="false" ref="infobaseForm">
        <div v-if="isCommunity">
          <FormItem label="小区名称" prop="communityName">
            <el-input v-model="fcommunityName" disabled size="small" placeholder="请输入" style="max-width: 485px;">
            </el-input>
          </FormItem>
          <FormItem label="楼栋" prop="street">
            <el-input v-model="fldShortName" size="small" disabled placeholder="请输入" style="max-width: 485px;">
            </el-input>
          </FormItem>
          <FormItem label="房号" prop="houseNumber">
            <el-input v-model="infoObj.roomNum" size="small" disabled placeholder="请输入" style="max-width: 485px;">
            </el-input>
          </FormItem>
        </div>
        <div v-if="!isCommunity">
                  <FormItem label="地址" prop="fcertAddress">
            <el-input v-model="fcertAddress" disabled size="small" placeholder="请输入" style="max-width: 485px;">
            </el-input>
          </FormItem>
        </div>
      </BaseForm>
      <div>
        <Button class="bottom-bit" type="primary" :loading="assessmentLoading" @click="assessment"
          style="width: 485px; margin-bottom:48px">提交</Button>
      </div>

      </Col>

    </Row>

  </Modal>
</template>

<script>
import BaseForm from '@/views/pledge/components/BaseForm.vue'
import { sampleResultDetail, certificateEdit } from '@/api/pledge'

import CityList from '../cityList.js'

export default {
  props: {
    infoModel: {
      type: Boolean,
      required: true,
    },
    isCommunity: {
      type: Boolean,
      required: true,
    },
    fcity: {
      type: String,
    },
    infoObj: {//父组件传过来的值
      type: Object,
    },
    fcommunityName: {
      type: String,
    },
    fcertAddress: {
      type: String,
    },
    fldShortName: {
      type: String,
    },
    ldCode: {
      type: String,
    },
  },
  components: {
    BaseForm,
  },
  data() {
    return {
      assessmentLoading: false,
      form: {
        buildingArea: '',//面积
        orientation: '',//朝向
        roomCnt: '',//房间数量
        parlourCnt: '',//客厅数量
        kitchenCnt: '',//厨房数量
        toiletCnt: '',//卫生间数量
        floors: '',//楼层
        totalFloors: '',//总楼层
        completionYear: '',//年代
        isHaveLift: '',//电梯
      },
      city: this.fcity,
      communityName: '',
      street: '',
      houseNumber: '',
      certAddress: '',
      cityList: [],
      cityObj: {},

    }
  },
  computed: {
    showInfo: {
      get() {
        return this.infoModel
      },
      set(newVal) {
        this.$parent.showinfoModel = newVal
      }

    }
  },
  watch: {
    showInfo(val) {
      if (val) {
        this.form = Object.assign(this.form, this.infoObj)
      }


    },
       fcity(val) {

      this.city = val
      this.cityChange(val)
    },
  },
  mounted() {
    this.cityList = this.$store.state.userAutoCityList.cityList
     this.cityChange( this.city)
    // this.cityObj = this.cityList[0]

  },
  methods: {
    ok() {
      this.showInfo = false
    },
    cityChange(cityCode) {
      this.cityList.map(item => {
        if (cityCode == item.cityCode) {
          this.cityObj = item
        }
      })
    },
    assessment() {
      let inputObj = this.$refs.infobaseForm.formValidate//子组件信息
      // if (!this.certAddress) {
      //   return
      // }

      if (!inputObj.buildingArea) {
        this.$msg.error({text: "请输入面积"} );
        return
      }
      this.saveSampleResultDetail()
    },
    async saveSampleResultDetail() {//根据小区估价-保存
      this.assessmentLoading = true
      let { cityCode, cityFullName, cityShortName, provinceCode, provinceFullName, provinceShortName } = this.cityObj
      let inputObj = this.$refs.infobaseForm.formValidate//子组件信息
      let params = {

      }
      params = this.infoObj
      params.ldCode = this.ldCode
      //
      for (let key in inputObj) {
        params[key] = inputObj[key]
      }
      if (inputObj.completionYear) {
        let date = new Date(inputObj.completionYear);
        params.completionYear = date.getFullYear().toString()
      }

      if (params.isHaveLift === '') {
        params.isHaveLift = ''
      } else if (params.isHaveLift === '无') {
        params.isHaveLift = false
      }
      else {
        params.isHaveLift = (params.isHaveLift=='有'||params.isHaveLift==true) ? true : false
      }
      let addparams = {
        certificateInfo: params,
        cityCode,
        platform: 1
      }

      certificateEdit(addparams).then(res => {
        this.$msg.success({text: "提交成功" } )
        this.showInfo = false
      })
        .catch(err => {
          // this.$msg.error(err.data)
        })
      this.assessmentLoading = false
    },
  }
}
</script>

<style scoped lang="scss">
.bottom-bit {
  margin-top: 10px;
  margin-left: 80px;
  margin-bottom: 40px;
}
</style>
