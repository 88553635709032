<template>
  <Modal v-model="showHand" :mask-closable="false" :footer-hide="true" width="1200">
    <div slot="header">手动提交估价</div>
    <Row :gutter="16" index="">
      <Col span="12" :offset="0">
      <div>
        <BaseForm :isShowMoreAndNot="false" :faForm="addressForm" ref="handbaseForm">
          <FormItem label="城市" prop="city">
            <Select v-model="city" @on-change="cityChange" style="max-width: 485px;">
              <Option v-for="item in cityList" :value="item.cityCode" :key="item.cityCode">
                {{ item.cityFullName }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="小区名称">
            <el-input v-model="communityName" maxlength="50" size="small" placeholder="请输入"
              style="max-width: 485px;"></el-input>
          </FormItem>
          <FormItem label="地址" prop="certAddress">
            <el-input v-model="certAddress" maxlength="50" size="small" @blur="getblur" placeholder="输入详细证载/房屋地址（包括楼栋、房号）"
              style="max-width: 485px;"></el-input>
            <div style="font-size: 14px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: #BFBFBF">示例：广州市天河区汇景北路154号xxx房</div>
          </FormItem>
        </BaseForm>
      </div>
      <div>
        <Button class="bottom-bit" type="primary" :loading="assessmentLoading" @click="assessment"
          style="width: 485px;">现在估价</Button>
      </div>

      </Col>
      <Col span="12">
      <div style="color: #262626; font-size: 18px;">什么是证载地址，如图：</div>
      <img class="zzdzImg" style="width: 500px; margin-top:15px" src="../../../assets/image/zzdz.gif" alt="">
      </Col>
    </Row>

  </Modal>
</template>

<script>
import BaseForm from '@/views/pledge/components/BaseForm.vue'
import { sampleResultDetail, certByAddress ,collectHouseAttribute} from '@/api/pledge'
import _ from 'lodash'

import CityList from '../cityList.js'

export default {
  props: {
    handModel: {
      type: Boolean,
      required: true,
    },
    fcity: {
      type: String,
    },
    fcommunityName: {
      type: String,
      default: ''
    },
    fcertAddress: {
      type: String,

    }
  },
  components: {
    BaseForm,
  },
  data() {
    return {
      assessmentLoading: false,
      addressForm: {
        buildingArea: '', //面积
        orientation: '', //朝向
        roomCnt: '', //房间数量
        parlourCnt: '', //客厅数量
        kitchenCnt: '', //厨房数量
        toiletCnt: '', //卫生间数量
        floors: '', //楼层
        totalFloors: '', //总楼层
        completionYear: '', //年代
        isHaveLift: '', //电梯
      },
      baseForm: {
        buildingArea: '', //面积
        orientation: '', //朝向
        roomCnt: '', //房间数量
        parlourCnt: '', //客厅数量
        kitchenCnt: '', //厨房数量
        toiletCnt: '', //卫生间数量
        floors: '', //楼层
        totalFloors: '', //总楼层
        completionYear: '', //年代
        isHaveLift: '', //电梯
      },
      city: this.fcity,
      communityName: this.fcommunityName,
      certAddress: this.fcertAddress,
      cityList: [],
      cityObj: {},
    }
  },
  computed: {
    showHand: {
      get() {
        return this.handModel
      },
      set(newVal) {
        this.$parent.showHandModel = newVal
      }

    }
  },
  watch: {
    fcity(val) {
      this.city = val
      this.cityChange(val)
    },
    fcommunityName(val) {
      this.communityName = val
    },
    fcertAddress(val) {
      this.certAddress = val
    },
  },
  mounted() {
    this.cityList = JSON.parse(localStorage.getItem('userAutoCityList'))
    // this.cityObj = this.cityList[0]
    this.cityChange(this.city)
  },
  methods: {
    async getblur() {
      let params = {
        condition: this.certAddress,
        cityCode: this.city
      }
      let res1 = await certByAddress(params)

     let params1 = {
        houseCode: res1.houseCode,
        cityCode: res1.cityCode
      }
      if ( !res1.houseCode) {
         this.addressForm = _.cloneDeep(this.baseForm)

        return
      }
      let res = await collectHouseAttribute(params1)


      if (res) {
        if (res.isHaveLift === true) {
          res.isHaveLift = '有'
        } else if (res.isHaveLift === false) {
          res.isHaveLift = '无'
        } else {
          res.isHaveLift = ''
        }
        this.addressForm = res

      } else {

        this.addressForm = _.cloneDeep(this.baseForm)

      }
    },
    ok() {
      this.showHand = false
    },
    cityChange(cityCode) {
      this.cityList.map(item => {
        if (cityCode == item.cityCode) {
          this.cityObj = item
        }
      })
    },
    assessment() {
      let inputObj = this.$refs.handbaseForm.formValidate//子组件信息
      if (!this.certAddress) {
        this.$msg.error({ text: "请输入房屋地址" });
        return
      }

      if (!inputObj.buildingArea) {
        this.$msg.error({ text: "请输入面积" });
        return
      }
      this.saveSampleResultDetail()
    },
    async saveSampleResultDetail() {//根据小区估价-保存

      this.assessmentLoading = true
      let { cityCode, cityFullName, cityShortName, provinceCode, provinceFullName, provinceShortName } = this.cityObj
      let inputObj = this.$refs.handbaseForm.formValidate//子组件信息
      let params = {
        "cityCode": cityCode,
        "cityFullName": cityFullName,
        "cityShortName": cityShortName,
        "provinceCode": provinceCode, //，默认
        "provinceFullName": provinceFullName,
        "provinceShortName": provinceShortName,
        "inputCertAddress": this.certAddress,//手动输入的地址
        "inputLpName": this.communityName,//小区名
        "evlType": 2, //估价方式: 0根据小区估价 1根据地址估价 2手动提交估价
        // "houseCode": this.houseCode,
        // "inputRoomNum": this.houseNumber, //房号名
        // "inputLdName": this.street, //楼栋名
        "inputBuiltArea": inputObj.buildingArea, //手动输入面积
        "inputOrientation": inputObj.orientation, //手动输入的朝向
        "inputRoomCnt": inputObj.roomCnt, //手动输入房数
        "inputParlourCnt": inputObj.parlourCnt, //手动输入的厅数
        "inputKitchenCnt": inputObj.kitchenCnt, //手动输入厨房数
        "inputToiletCnt": inputObj.toiletCnt, //手动输入卫生数
        "inputCompletionYear": inputObj.completionYear, //手动输入的年代
        "inputTotalFloors": inputObj.totalFloors, //手动输入总楼层
        "inputFloors": inputObj.floors, //手动输入的楼层
        "inputIsHaveLift": inputObj.isHaveLift, //手动输入的电梯
        "platform": 1
      }
      if (inputObj.completionYear) {
        let date = new Date(inputObj.completionYear);
        params.inputCompletionYear = date.getFullYear().toString()
      }

      if (params.inputIsHaveLift === '') {
        params.inputIsHaveLift = ''
      } else if (params.inputIsHaveLift === '无') {
        params.inputIsHaveLift = false
      }
      else {
        params.inputIsHaveLift = params.inputIsHaveLift ? true : false
      }

      let res = await sampleResultDetail(params).catch(err => {
        this.assessmentLoading = false
      })
      this.assessmentLoading = false
      this.$router.push({
        path: '/Empowerment/pledge/houseProperty/resultValuation',
        query:
        {
          cityCode: cityCode,
          sampleHistoryId: res.sampleHistoryId
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.bottom-bit {
  margin-top: 10px;
  margin-left: 80px;
}
</style>
